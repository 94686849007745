.btn-primary {
  background-color: #d68827;
  border: #d68827;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary:hover {
  background-color: #4e4e4e;
}

.btn-primary::after {
  background-color: #d68827;
  border: #d68827;
}
.dark-mode .ql-picker.ql-expanded .ql-picker-label,
.dark-mode .active > .nk-menu-link,
.dark-mode .is-theme .nk-menu-link:hover .nk-menu-icon,
.dark-mode .is-theme .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.dark-mode .is-theme .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon,
.dark-mode .is-theme .nk-menu-sub .nk-menu-link:hover,
.dark-mode .is-theme .nk-menu-sub .active > .nk-menu-link,
.dark-mode .page-link:hover {
  color: #d68827 !important;
}

.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  background: #d68827;
}

.nk-menu-link:hover,
.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon,
.nk-menu-link:hover .count,
.nk-menu-sub .nk-menu-link:hover,
.nk-menu-sub .active > .nk-menu-link,
.nk-menu-sm .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:focus,
.nk-menu-main .nk-menu-item.active > .nk-menu-link,
.nk-menu-main .nk-menu-item.current-menu > .nk-menu-link,
.nk-menu-main .nk-menu-item:hover > .nk-menu-link,
.is-light .nk-menu-link:hover,
.is-light .active > .nk-menu-link,
.active > .nk-menu-link,
.active > .nk-menu-link .count,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.active .nav-link,
.nk-menu-footer .nk-menu-icon,
.nk-menu-footer .nk-menu-link:hover,
.nk-footer-copyright a:hover,
.page-link:hover,
.list-plain a:hover,
.link-check li a:hover,
.link-list a:hover,
.link-list-opt a:hover,
.link-list-plain a:hover,
.link-list-menu li.active > a,
.link-list-menu a.active,
.link-list-menu a:hover,
.link-list-menu li.active > a .icon,
.link-list-menu a.active .icon,
.link-list-menu a:hover .icon,
.link-list-menu li.active > a:after,
.link-list-menu a.active:after,
.link-list-menu a:hover:after,
.list-checked li:before,
.list-step li.list-step-current:before,
.accordion-s2 .accordion-head .title,
.accordion-s3 .accordion-head .title,
.badge-outline-primary,
.badge-dim.badge-primary,
.badge-dot.badge-primary,
.badge-dim.badge-outline-primary,
.alert-primary,
.form-clip,
.form-text-hint,
.search-submit:hover,
.attach-item .icon,
.attach-download:hover span,
.nk-reply-meta-info .whom,
.nk-msg-tags li > span .icon,
.nk-msg-menu-item a:hover,
.nk-msg-menu-item.active a,
.user-balance,
.user-avatar[class*="-primary-dim"],
a,
.dropzone .dz-message-text span,
.nk-switch-icon.active,
.link-list-plain a .icon,
.chat-upload-option a,
.is-unread .chat-context .status,
.add-opt:hover .sub-text,
.add-opt:hover .icon,
.icon[class*="bg-primary-dim"],
.nk-tb-list .tb-asterisk a {
  color: #d68827;
}
